.modal-dialog h1, .modal-dialog h2, .modal-dialog h3 {
  font-size: 30px;
  margin-top: 20px;
  margin-bottom: 10px;
}
#coverage-container strong {
  font-weight: bold;
  word-spacing: 2px;
}
#coverage-container * {
  outline: 0!important;
}

.form-group:not(:last-child) {
  margin-bottom: 0px !important;
}

.form-group:last-child {
  margin-top: 18px;
}
