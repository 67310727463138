#client-app-appointment .btn:hover, #client-app-appointment .btn:focus, #client-app-appointment .btn.focus {
    color: #333;
    text-decoration: none;
  }
  #client-app-appointment .glyphicon.glyphicon-calendar {
    position: initial;
  }
  #client-app-appointment .checkbox label {
    line-height: 1.4;
  }
  